const getAPILocationBasedOnURL = () => {
  const currentUrl = window.location.hostname.toLowerCase();

  switch (true) {
    case currentUrl.includes("app."):
      return "https://api.regask.com";
    case currentUrl.includes("qa."):
      return "https://qaapi.regask.com";
    case currentUrl.includes("preprod."):
      return "https://preprodapi.regask.com";
    case currentUrl.includes("dev"):
      return "https://devapi.regask.com";
    case currentUrl.includes("waftest"):
      return "https://waftestapi.regask.com";
    default:
      return process.env.API_GATEWAY;
  }
};

type Environment =
  | "production"
  | "qa"
  | "preprod"
  | "dev"
  | "local"
  | "unknown";
const getEnvironment = (): Environment => {
  const currentUrl = window.location.hostname.toLowerCase();

  switch (true) {
    case currentUrl.includes("app."):
      return "production";
    case currentUrl.includes("qa."):
      return "qa";
    case currentUrl.includes("preprod."):
      return "preprod";
    case currentUrl.includes("dev"):
      return "dev";
    case currentUrl.includes("localhost"):
      return "local";
    default:
      return "unknown";
  }
};

export const isProd = () => {
  const currentUrl = window.location.hostname.toLowerCase();
  return currentUrl.includes("app.");
};

export const config = {
  apiGateway: getAPILocationBasedOnURL(),
  environment: getEnvironment(),
  nodeEnv: window.location.hostname.toLowerCase().includes("app.regask")
    ? "production"
    : "not production",
  apiPath: {
    dms: {},
    ims: {},
    nms: {},
    ams: {},
    rgms: {},
    rqms: {
      postAlertRequests: "/api/rqms/request/list/alertsSubRequests",
    },
    gw: {
      // IMS Service
      postAccountAddUser: "/api/ims/account/addUser",
      postAccountEditProfile: "/api/ims/account/editProfile",
      postAccountChangePassword: "/api/ims/account/changePassword",
      getCompanyList: "/api/ims/account/company/list/",
      addCompany: "/api/ims/account/company/add",
      editCompany: "/api/ims/account/company/edit",
      postAfterResetPassword: "/api/account/afterResetPassword",
      postResetPassword: "/api/account/resetPassword",
      postLogin: "/api/account/login",
      postLogout: "/api/ims/account/logout",
      getUserById: "/api/ims/account/list",
      listAssignee: "/api/ims/account/list/assignees",
      monitoredCountryList: "/api/ims/account/listCountry",
      saveAlertFilter: "/api/ims/account/saveAlertFilter",
      deleteAlertFilter: "/api/ims/account/deleteAlertFilter",
      saveCandidateFilter: "/api/ims/account/saveCandidateFilter",
      deleteCandidateFilter: "/api/ims/account/deleteCandidateFilter",
      getUserFromSameCompany: "/api/v2/company/users/:id",
      getAllUserFromSameCompany: "/api/v2/company/users",

      // AGMS
      getClientRequestList: "/api/agms/aggregation/request/list/client",
      getInternalRequestList: "/api/agms/aggregation/request/list/internal",
      getInternalRequestFlatList:
        "/api/agms/aggregation/request/list/internal/flat",
      getInternalListNested:
        "/api/agms/aggregation/request/list/internal/nested",
      postRequestCreateUpdate: "/api/agms/aggregation/request/createUpdate",
      getLinkedRequestList: "/api/agms/aggregation/request/fetch/linked",
      getTerritoryList: "/api/agms/aggregation/common/territory/list/",
      postGuidebookCreateUpdate: "/api/agms/aggregation/guidebook/createUpdate",
      getFilterMetaData: "/api/agms/aggregation/common/filterMetaData",
      getGuidebookList: "/api/agms/aggregation/guidebook/list",
      // To-DO: This API is depricated. Remove the usage from UI
      getRequestList: "/api/agms/aggregation/request/list",
      addRequestComment: "/api/agms/aggregation/request/comment/add",

      getTagOptions: "/api/agms/aggregation/tags/list",
      getTagsByIds: "/api/agms/aggregation/tags/getTagsByIds",
      createTags: "/api/agms/aggregation/tags/create",
      // getAuthorityList: '/api/agms/aggregation/regulation/getSources',
      getAuthorityList: "/api/publishers",
      postRegulationList: "/api/agms/aggregation/regulation/list",

      // RQMS
      postAssignRequest: "/api/rqms/request/assign",
      getRequestHistory: "/api/rqms/request/history/?",
      getRequestSubTypeList: "/api/rqms/request/requestSubType/list",
      getRequestStatusList: "/api/rqms/request/status/list",
      getRequestComments: "/api/rqms/request/comment/list",
      getRequestsByIds: "/api/rqms/request/listByIds",
      markReadRequestComment: "/api/rqms/request/comment/markRead",
      getReqCommentCount: "/api/rqms/request/comment/count",
      getRequestCount: "/api/rqms/request/status/count",
      getSubRequest: "/api/rqms/subRequests/:id",
      deleteRequest: "/api/rqms/request/delete",

      // ams

      getAlertComments: "/api/ams/alert/comment/list",
      markReadAlertComment: "/api/ams/alert/comment/markRead",
      getCommentCount: "/api/ams/alert/comment/count",
      postDeleteAlert: "/api/ams/alert/delete",
      alertDetectionCompanyList: "/api/rqms/request/list/alert/company",
      getAlertListById: "/api/ams/alert/alertListById",
      postAlertUnPublish: "/api/ams/alert/unpublish",

      setCandidateAsNotRelevant: "/api/ams/alert/candidate/markAsNotRelevant",
      deleteCandidate: "/api/ams/alert/candidate/delete",
      setCandidateAsDuplicate: "/api/ams/alert/candidate/markAsDuplicate",
      getCountDuplicateAlerts: "/api/ams/alert/candidate/duplicatesCount",

      updateLanguage: "/api/ams/alert/candidate/updateLanguage",

      // DMS
      getDocumentDownload: "/api/dms/document/download/",
      postUploadDocs: "/api/dms/document/uploadDocs",
      getDocumentDetail: "/api/dms/document/fetch/",
      getBulkDocumentDetails: "/api/dms/document/bulkDocument",

      // hsms
      getHSCharts: "/api/hsms/signal/charts",
      getHSTopicArticles: "/api/hsms/signal/topic",
      getHSChartsFilterMetadata: "/api/hsms/signal/chartsFilterMetadata",
      getHSTopicFilterMetadata: "/api/hsms/signal/topicFilterMetadata",
      getArticleSignals: "/api/hsms/articles/getArticleSignals",
      getSignals: "/api/hsms/signal/getSignals",
      updateArticleSignals: "/api/hsms/signal/updateArticleSignals",
      createSignalManually: "/api/hsms/signal/createSignalManually",

      // Nms
      getNotificationList: "/api/nms/notification/list/",
      postNotificationtMarkRead: "/api/nms/notification/markRead",
      postNotificationMarkRead: "/api/nms/notification/markRead",

      // rgms

      // gms guidebook
      getGuidebookTopicList: "/api/gms/guidebook/topic/list",
      getGuidebookProductList: "/api/gms/guidebook/product/list",
      postGuidebookStatus: "/api/gms/guidebook/userStatus/createUpdate",

      // KMS
      getKolDetailList: "/api/kms/kol/details/list",
      getKolData: "/api/kms/kol/list",
      expertiseList: "/api/kms/kol/list/areaOfExpertise",
      getKolFilterList: "/api/kms/kol/list",

      getUIRules: "/api/rules/get",

      // Publisher and Feeds Management Services
      createPublisher: "/api/publishers/create",
      updatePublisher: "/api/publishers/update",
      deletePublisher: "/api/publishers/delete",
      getPublisher: "/api/publishers/get",
      listPublishers: "/api/publishers",
      createFeed: "/api/feeds/create",
      updateFeed: "/api/feeds/update",
      deleteFeed: "/api/feeds/delete",
      getFeed: "/api/feeds/get",
      listFeeds: "/api/feeds",

      // Alert Service
      getAlertClientCustomFieldList: "/api/v2/alert-client-custom-fields",
      createAlertClientCustomField: "/api/v2/alert-client-custom-fields",
      updateAlertClientCustomField: "/api/v2/alert-client-custom-fields/:id",
      deleteAlertClientCustomField: "/api/v2/alert-client-custom-fields/:id",
      getAlertClientCustomFieldLimitation:
        "/api/v2/alert-client-custom-fields-limitation",

      // Document V2 Service
      uploadDocument: "/api/v2/document-upload",
      getDocuments: "/api/v2/documents",
      getDocumentsByAttachment: "/api/v2/documents/search/by-attachment",
      getDocumentDetails: "/api/v2/documents/:id",
      getDocumentFoldersHierarchies:
        "/api/v2/documents/:id/folders-hierarchies",
      deleteDocument: "/api/v2/documents/:id",
      getRecentDocuments: "/api/v2/documents/recently-opened",
      createLabel: "/api/v2/documents/labels",
      getLabelList: "/api/v2/documents/labels",
      getLabelDetails: "/api/v2/documents/labels/:id",
      updateLabelDetails: "/api/v2/documents/labels/:id",
      updateDocumentLabels: "/api/v2/documents/:documentId/labels",
      updateDocumentFolders: "/api/v2/documents/:documentId/folders",
      shareDocument: "/api/v2/documents/:id/share-requests",
      addDocumentToFolder: "/api/v2/folders/:folderId/documents/:documentId",
      removeDocumentFromFolder:
        "/api/v2/folders/:folderId/documents/:documentId",
      getFoldersHierarchy: "/api/v2/folders-hierarchy",
      getDocumentByIds: "/api/v2/documents/search/by-ids",
      logDocumentDownloaded: "/api/v2/log/document-downloaded/:documentId",
      createFolder: "/api/v2/folders",
      updateFolder: "/api/v2/documents/folders/:folderId",
      deleteFolder: "/api/v2/documents/folders/:folderId",
      getFolderDetails: "/api/v2/folders/:folderId",

      // Comment V2 Service
      createComment: "/api/v2/:entity/:entityId/comments",
      getComments: "/api/v2/:entity/:entityId/comments",
      getCommentsCount: "/api/v2/:entity/:entityId/comments/count",
      getCommentsUnreadCount: "/api/v2/:entity/:entityId/comments/unread-count",
      editComment: "/api/v2/comments/:commentId",

      // Audit Log V2 Service
      searchAuditLog: "/api/v2/audit-log/search",
      getAuditLogFilterOptions: "/api/v2/audit-log/search/filter-options",

      // Identity Service
      ssoDomainVerification: "/api/v2/auth/sso/domain-verification",
    },
  },
};

export type ConfigType = typeof config;
